// import VueRouter from 'vue-router'
// const About = { template: '<div>About</div>' }
// import HomePage from '../components/HomePage.vue'
// import NavBar from '../components/NavBar.vue'
import UserLogin from '../views/user/UserLogin.vue'
import UserLogout from '../views/user/UserLogout.vue'
import UserList from '../views/user/UserList.vue'
import CreateTranslations from '../views/translation/CreateTranslations.vue'
import TranslationList from '../views/translation/TranslationList.vue'

import {createRouter, createWebHashHistory} from 'vue-router'
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  { name: 'TranslationList', path: '/', component: TranslationList },
  { name: 'CreateTranslations', path: '/translations/create', component: CreateTranslations },
  { name: 'UserLogin', path: '/user/login', component: UserLogin },
  { name: 'UserLogout', path: '/user/logout', component: UserLogout },
  { name: 'UserList', path: '/user/list', component: UserList },
]

// export default new VueRouter({
//     scrollBehavior: () => ({ y: 0 }),
//     // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
//     history: VueRouter.createWebHashHistory(),
//     routes: routes
// });

const router = createRouter({
  scrollBehavior: () => ({ y: 0 }),
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes: routes
});
export default router;
