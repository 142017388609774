<template>
  <n-space vertical>
    <div>
      <span style="display:inline-block;float:left;margin-top:0px;margin-right:5px;">
        <n-button type="info" @click="onClickCreateUserBtn">
          添加用户
        </n-button>
      </span>
    </div>
    <n-data-table
      remote
      :columns="columns"
      :data="list"
      :bordered="false"
      :row-key="rowKey"
      @update:checked-row-keys="onRowChecked"
      @update:sorter="onSorterChanged"
    />
    <n-pagination
      v-model:page="listQuery.page"
      v-model:page-size="listQuery.limit"
      :page-count="listQuery.totalPage"
      show-size-picker
      :max-height="250"
      :scroll-x="1800"
      :page-sizes="[10, 20, 50, 100]"
      @update:page="onPageChanged"
      @update:page-size="onPageSizeChanged"
    />
    <!-- 添加用户dialog -->
    <n-modal
      v-model:show="showCreateUserDialog"
      :mask-closable="false"
      preset="dialog"
      title="添加用户"
      content=""
      positive-text="确认"
      negative-text="算了"
      @positive-click="onClickCreateUserDialogYes"
      @negative-click="onClickCreateUserDialogNo"
    >
      <div>
        <span style="display:inline-block">用户名</span>
        <n-input v-model:value="createParams.username" type="text" placeholder="用户名" />
        <span style="display:inline-block">密码</span>
        <n-input
          type="password"
          v-model:value="createParams.password"
          show-password-on="mousedown"
          placeholder="密码"
          :maxlength="10"
        />
      </div>
    </n-modal>
    <!-- 重置密码dialog -->
    <n-modal
      v-model:show="showPasswordDialog"
      :mask-closable="false"
      preset="dialog"
      title="重置密码"
      content=""
      positive-text="确认"
      negative-text="算了"
      @positive-click="onClickPasswordDialogYes"
      @negative-click="onClickPasswordDialogNo"
    >
      <div>
        <span style="display:inline-block">新密码</span>
        <n-input
          type="password"
          v-model:value="newPassword"
          show-password-on="mousedown"
          placeholder="密码"
          :maxlength="10"
        />
      </div>
    </n-modal>
    <!-- 删除用户dialog -->
    <n-modal
      v-model:show="showDeleteUserDialog"
      :mask-closable="false"
      preset="dialog"
      title="确认删除"
      :content="'你是否确认要删除用户：'+deleteUsername"
      positive-text="确认"
      negative-text="算了"
      @positive-click="onClickDeleteUserDialogYes"
      @negative-click="onClickDeleteUserDialogNo"
    />
  </n-space>
</template>

<script>
// import { defineComponent } from "vue";
import { h, ref, reactive } from "vue";
import { NButton, useMessage } from "naive-ui";
import { apiPostUser, apiGetUsers, apiPutUserPassword } from "@/apis/user/user";

export default {
  data() {
      return {
        listQuery: {
          page: 1,
          totalPage: 0,
          limit: 10,
          order: 'descend',
          orderBy: '',
        },
        createParams: {
          username: '',
          password: '',
        },
        selectedIds: [],
        userId: 0,
        newPassword: '',
        showCreateUserDialog: false,
      }
  },
  setup() {
    window.$message = useMessage()

    let showPasswordDialogRef = ref(false)
    let updatePasswordUserIdRef = ref(0)
    const showPasswordDialogFunc = (row) => {
      showPasswordDialogRef.value = true
      updatePasswordUserIdRef.value = row.id
    }

    let showDeleteUserDialogRef = ref(false)
    let deleteUserIdRef = ref(0)
    let deleteUsernameRef = ref('')
    const showDeleteUserDialogFunc = (row) => {
      showDeleteUserDialogRef.value = true
      deleteUserIdRef.value = row.id
      deleteUsernameRef.value = row.username
    }
    return {
      showPasswordDialog: showPasswordDialogRef,
      updatePasswordUserId: updatePasswordUserIdRef,
      showDeleteUserDialog: showDeleteUserDialogRef,
      deleteUserId: deleteUserIdRef,
      deleteUsername: deleteUsernameRef,
      columns: reactive([
        {
          type: "selection",
          fixed: "left",
        },
        {
          title: "ID",
          key: "id",
          width: 50,
          fixed: "left",
          sorter: true,
          sortOrder: false,
        },
        {
          title: "用户名",
          key: "username",
          ellipsis: {
            tooltip: true
          },
          fixed: "left",
          sorter: true,
          sortOrder: false,
        },
        {
          title: 'Action',
          key: 'actions',
          render(row) {
            return h('div', {},
              [
                h(NButton, {
                  strong: true,
                  tertiary: true,
                  size: "small",
                  onClick: () => showPasswordDialogFunc(row)
                },{
                  default: () => "重置密码"
                }),
                h('div', {
                  style:'display:inline-block;margin-left:5px;'
                }),
                h(NButton, {
                  strong: true,
                  tertiary: true,
                  size: "small",
                  onClick: () => showDeleteUserDialogFunc(row)
                }, {
                  default: () => "删除"
                })
              ]
            )
          }
        }
      ]),
      rowKey: (row) => row.id,
      list: ref([]),
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    error() {
      window.$message.error("")
    },
    onPageChanged(page) {
      this.listQuery.page = page
      this.getList()
    },
    onPageSizeChanged(pageSize) {
      this.listQuery.page = 1
      this.listQuery.limit = pageSize
      this.getList()
    },
    onRowChecked(rowKeys) {
      this.selectedIds = rowKeys
    },
    onSorterChanged(sorter) {
      // 排序
      for(let i=0; i<this.columns.length; i++) {
        let column = this.columns[i]
        if (column.key == sorter.columnKey) {
          column.sortOrder = sorter.order
          this.listQuery.orderBy = column.key
          this.listQuery.order = sorter.order
          this.getList()
          break
        }
      }
    },
    onClickCreateUserBtn() {
      this.showCreateUserDialog = true
    },
    onClickCreateUserDialogYes() {
      apiPostUser(this.createParams).then(() => {
        this.getList()
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
      this.showCreateUserDialog = false
    },
    onClickCreateUserDialogNo() {
      this.showCreateUserDialog = false
    },
    onClickPasswordDialogYes() {
      const params = {
        id: this.updatePasswordUserId,
        password: this.newPassword,
      }
      apiPutUserPassword(params).then((response) => {
        if (response.data.count > 0) {
          this.getList()
        }
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
      this.showPasswordDialog = false
    },
    onClickPasswordDialogNo() {
      this.showPasswordDialog = false
    },
    onClickDeleteUserDialogYes() {},
    onClickDeleteUserDialogNo() {},
    getList() {
      // const params = {
      //   'page': this.page,
      //   'limit': this.limit,
      //   'order': this.order,
      //   'orderBy': this.orderBy,
      // }
      apiGetUsers(this.listQuery).then(response => {
        this.listQuery.page = response.data.page
        this.listQuery.totalPage = response.data.totalPage
        this.listQuery.limit = response.data.limit
        this.list = []
        for (var i=0; i<response.data.list.length; i++) {
          this.list.push(response.data.list[i])
        }
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
    },
  },
  computed: {
      showXXX() {
        // return true
        // 是否部分选择
        return true
      }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
