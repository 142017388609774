import http from '@/http'

export function apiPostUser(data) {
  return http({
    url: '/api/user',
    method: 'post',
    data
  })
}

export function apiGetUsers(params) {
  return http({
    url: '/api/users',
    method: 'get',
    params: params
  })
}

export function apiPutUserPassword(data) {
  return http({
    url: '/api/user/password',
    method: 'put',
    data
  })
}
