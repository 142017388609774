<template>
  <n-space vertical>
    <n-select v-model:value="group" :options="groups" @update:value="onGroupSelected" clearable/>
  </n-space>
</template>

<script>
// import { defineComponent } from "vue";
import { useMessage } from "naive-ui";
import { apiGetGroups } from "@/apis/translation/group";

export default {
  props: ['propProject'],
  data() {
    return {
      project: '',
      group: '',
      groups: [
      ],
    }
  },
  setup() {
    // console.log(process.env.VUE_APP_BASE_API)
    window.$message = useMessage()
    return {
    };
  },
  mounted() {
    // this.project = this.propProject
    // this.getGroups()
  },
  methods: {
    onGroupSelected() {
      this.$emit('groupSelected', this.group)
    },
    getGroups() {
      const params = {
        project: this.project
      }
      apiGetGroups(params).then(response => {
        this.groups = []
        for (var i=0; i< response.data.list.length; i++)
        {
          if (response.data.list[i].group === '') {
            continue
          }
          this.groups.push({
            label: response.data.list[i].group,
            value: response.data.list[i].group
          })
        }
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
    },
  },
  watch:{ 
    propProject:{
      handler(newVal/*, oldVal*/) {
        this.project = newVal
        this.getGroups()
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
