import http from '@/http'

export function apiGetProjects(params) {
  return http({
    url: '/api/translation/projects',
    method: 'get',
    params: params
  })
}


