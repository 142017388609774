<template>
  <n-space vertical>
    <div>
      <span style="display:inline-block;float:left;margin-top:5px;margin-right:5px;">项目</span>
      <!-- 项目选择 -->
      <div style="display:inline-block;float:left;width:200px;margin-right:5px;">
          <project-selector @projectSelected="onProjectSelected" :propProject="project"></project-selector>
      </div>
      <span style="display:inline-block;float:left;margin-top:6px;margin-right:5px;">分组</span>
      <div style="display:inline-block;float:left;width:200px;margin-right:5px;">
        <!-- 分组选择 -->
        <group-selector @groupSelected="onGroupSelected" :propProject="project"></group-selector>
      </div>
      <span style="display:inline-block;float:left;margin-top:0px;margin-right:5px;">
        <n-button type="error" :disabled="selectedIds.length==0" @click="onClickDeleteBtn">
          删除
        </n-button>
      </span>
      <span style="display:inline-block;float:left;margin-top:0px;margin-right:5px;">
        <n-button type="success" :disabled="selectedIds.length==0" @click="onClickGoogleBtn">
          Google翻译
        </n-button>
      </span>

      <div style="display:inline-block;float:right;margin-top:0px;height:30px;width:50px;">
        <!-- 列选择 -->
        <column-selector @columnsChanged="onColumnsChanged" :propItems="langs" :propCheckedColumns="checkedColumns"></column-selector>
      </div>
      <div style="display:inline-block;float:right;height:30px;margin-right:5px;width:150px;margin-right:5px;">
        <!-- 语言选择 -->
        <lang-selector @langSelected="onLangSelected" :propLang="lang"></lang-selector>
      </div>
      <div style="display:inline-block;float:right;margin-top:0px;height:30px;width:150px;margin-right:5px;">
        <!-- 平台选择 -->
        <n-select v-model:value="platform" :options="platforms" @update:value="onPlatformSelected"/>
      </div>
      <div style="display:inline-block;float:right;margin-top:5px;height:30px;width:50px;">
        <a :href="exportUrl" target="_blank">导出</a>
      </div>
    </div>
    <n-data-table
      remote
      :columns="columns"
      :data="list"
      :bordered="false"
      :row-key="rowKey"
      @update:checked-row-keys="onRowChecked"
      @update:sorter="onSorterChanged"
    />
    <n-pagination
      v-model:page="page"
      v-model:page-size="limit"
      :page-count="totalPage"
      show-size-picker
      :max-height="250"
      :scroll-x="1800"
      :page-sizes="[10, 20, 50, 100]"
      @update:page="onPageChanged"
      @update:page-size="onPageSizeChanged"
    />
    <n-modal
      v-model:show="showDeleteDialog"
      :mask-closable="false"
      preset="dialog"
      title="确认删除"
      content="你确认要删除这些记录吗"
      positive-text="确认"
      negative-text="算了"
      @positive-click="onClickDeleteDialogYes"
      @negative-click="onClickDeleteDialogNo"
    />
    <n-modal
      v-model:show="showGoogleDialog"
      :mask-closable="false"
      preset="dialog"
      title="Google翻译"
      content=""
      positive-text="确认"
      negative-text="算了"
      @positive-click="onClickGoogleDialogYes"
      @negative-click="onClickGoogleDialogNo"
    >
      <div>
        <span style="display:inline-block">源语言</span>
        <lang-selector @lang-selected="onGoogleFromLangSelected" :propLang="googleFromLang"></lang-selector>
        <span style="display:inline-block">目的语言</span>
        <lang-selector @langSelected="onGoogleToLangSelected" :propLang="googleToLang"></lang-selector>
        <div>
          <div style="display:inline-block;float:left">
            强制更新已翻译字段：
          </div>
          <div style="display:inline-block;float:left">
            <n-switch v-model:value="googleForce" />
          </div>
        </div>
      </div>
    </n-modal>
  </n-space>
</template>

<script>
// import { defineComponent } from "vue";
import { h, ref, reactive } from "vue";
import { useMessage, NInput } from "naive-ui";
import { apiPutTranslation, apiPostGoogle } from "@/apis/translation/translation";
import { apiGetTranslations, apiDeleteTranslations } from "@/apis/translation/translations";
// import { apiGetProjects } from "@/apis/translation/project";
// import { apiGetGroups } from "@/apis/translation/group";
import { getToken } from "@/utils/token";
import LangSelector from "@/components/LangSelector"
import ProjectSelector from "@/components/ProjectSelector"
import GroupSelector from "@/components/GroupSelector"
import ColumnSelector from "@/components/ColumnSelector"
import { saveColumns, readColumns } from "@/utils/utils"

const updateLang = (id, lang, msg) => {
  const params = {
    'id': id,
    'params': {
      [lang]: msg
    }
  }
  apiPutTranslation(params)
}
const list = ref([])

const createColumns = (showColumns) => {
  let arr = [
    {
      type: "selection",
      fixed: "left",
    },
    {
      title: "ID",
      key: "id",
      width: 50,
      fixed: "left",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "项目",
      key: "project",
      ellipsis: {
        tooltip: true
      },
      fixed: "left",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "分组",
      key: "group",
      ellipsis: {
        tooltip: true
      },
      fixed: "left",
      sorter: true,
      sortOrder: false,
    },
    {
      title: "Key",
      key: "key",
      render(row, index) {
        return h(NInput, {
          value: row.key,
          onUpdateValue(v) {
            list.value[index]['key'] = v;
            const params = {
              'id': list.value[index]['id'],
              'key': v
            }
            apiPutTranslation(params)
          }
        });
      },
      sorter: true,
      sortOrder: false,
      hidden: true,
    },
  ]
  const columnItems = [
    {
      title: "中文",
      key: "zh_CN",
      render(row, index) {
        return h(NInput, {
          value: row.zh_CN,
          onUpdateValue(v) {
            list.value[index]['zh_CN'] = v;
            updateLang(list.value[index]['id'], 'zh_CN', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "英语",
      key: "en_US",
      render(row, index) {
        return h(NInput, {
          value: row.en_US,
          onUpdateValue(v) {
            list.value[index]['en_US'] = v;
            updateLang(list.value[index]['id'], 'en_US', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "保加利亚",
      key: "bg_BG",
      render(row, index) {
        return h(NInput, {
          value: row.bg_BG,
          onUpdateValue(v) {
            list.value[index]['bg_BG'] = v;
            updateLang(list.value[index]['id'], 'bg_BG', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "波斯尼亚语",
      key: "bs_BA",
      render(row, index) {
        return h(NInput, {
          value: row.bs_BA,
          onUpdateValue(v) {
            list.value[index]['bs_BA'] = v;
            updateLang(list.value[index]['id'], 'bs_BA', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "捷克语",
      key: "cs_CZ",
      render(row, index) {
        return h(NInput, {
          value: row.cs_CZ,
          onUpdateValue(v) {
            list.value[index]['cs_CZ'] = v;
            updateLang(list.value[index]['id'], 'cs_CZ', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "德语",
      key: "",
      render(row, index) {
        return h(NInput, {
          value: row.de_DE,
          onUpdateValue(v) {
            list.value[index]['de_DE'] = v;
            updateLang(list.value[index]['id'], 'de_DE', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "希腊语",
      key: "el_GR",
      render(row, index) {
        return h(NInput, {
          value: row.el_GR,
          onUpdateValue(v) {
            list.value[index]['el_GR'] = v;
            updateLang(list.value[index]['id'], 'el_GR', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "西班牙语",
      key: "es_ES",
      render(row, index) {
        return h(NInput, {
          value: row.es_ES,
          onUpdateValue(v) {
            list.value[index]['es_ES'] = v;
            updateLang(list.value[index]['id'], 'es_ES', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "法语",
      key: "fr_FR",
      render(row, index) {
        return h(NInput, {
          value: row.fr_FR,
          onUpdateValue(v) {
            list.value[index]['fr_FR'] = v;
            updateLang(list.value[index]['id'], 'fr_FR', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "克罗地亚语",
      key: "hr_HR",
      render(row, index) {
        return h(NInput, {
          value: row.hr_HR,
          onUpdateValue(v) {
            list.value[index]['hr_HR'] = v;
            updateLang(list.value[index]['id'], 'hr_HR', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "匈牙利语",
      key: "hu_HU",
      render(row, index) {
        return h(NInput, {
          value: row.hu_HU,
          onUpdateValue(v) {
            list.value[index]['hu_HU'] = v;
            updateLang(list.value[index]['id'], 'hu_HU', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "意大利语",
      key: "it_IT",
      render(row, index) {
        return h(NInput, {
          value: row.it_IT,
          onUpdateValue(v) {
            list.value[index]['it_IT'] = v;
            updateLang(list.value[index]['id'], 'it_IT', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "立陶宛语",
      key: "lt_LT",
      render(row, index) {
        return h(NInput, {
          value: row.lt_LT,
          onUpdateValue(v) {
            list.value[index]['lt_LT'] = v;
            updateLang(list.value[index]['id'], 'lt_LT', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "波兰语",
      key: "pl_PL",
      render(row, index) {
        return h(NInput, {
          value: row.pl_PL,
          onUpdateValue(v) {
            list.value[index]['pl_PL'] = v;
            updateLang(list.value[index]['id'], 'pl_PL', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "葡萄牙语",
      key: "pt_PT",
      render(row, index) {
        return h(NInput, {
          value: row.pt_PT,
          onUpdateValue(v) {
            list.value[index]['pt_PT'] = v;
            updateLang(list.value[index]['id'], 'pt_PT', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "罗马尼亚语",
      key: "ro_RO",
      render(row, index) {
        return h(NInput, {
          value: row.ro_RO,
          onUpdateValue(v) {
            list.value[index]['ro_RO'] = v;
            updateLang(list.value[index]['id'], 'ro_RO', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "俄语",
      key: "ru_RU",
      render(row, index) {
        return h(NInput, {
          value: row.ru_RU,
          onUpdateValue(v) {
            list.value[index]['ru_RU'] = v;
            updateLang(list.value[index]['id'], 'ru_RU', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "斯洛伐克语",
      key: "sk_SK",
      render(row, index) {
        return h(NInput, {
          value: row.sk_SK,
          onUpdateValue(v) {
            list.value[index]['sk_SK'] = v;
            updateLang(list.value[index]['id'], 'sk_SK', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "塞尔维亚语",
      key: "sr_SP",
      render(row, index) {
        return h(NInput, {
          value: row.sr_SP,
          onUpdateValue(v) {
            list.value[index]['sr_SP'] = v;
            updateLang(list.value[index]['id'], 'sr_SP', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "乌克兰语",
      key: "uk_UK",
      render(row, index) {
        return h(NInput, {
          value: row.uk_UK,
          onUpdateValue(v) {
            list.value[index]['uk_UK'] = v;
            updateLang(list.value[index]['id'], 'uk_UK', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
    {
      title: "越南语",
      key: "vi_VN",
      render(row, index) {
        return h(NInput, {
          value: row.vi_VN,
          onUpdateValue(v) {
            list.value[index]['vi_VN'] = v;
            updateLang(list.value[index]['id'], 'vi_VN', v)
          }
        });
      },
      sorter: true,
      sortOrder: false,
    },
  ];
  const showColumnSet = new Set(showColumns);
  for (let columnItem of columnItems) {
    if (showColumnSet.has(columnItem.key)) {
      arr.push(columnItem)
    }
  }
  return arr
}

export default {
  components: {
    'lang-selector': LangSelector,
    'project-selector': ProjectSelector,
    'group-selector': GroupSelector,
    'column-selector': ColumnSelector,
  },
  data() {
    return {
      page: 1,
      totalPage: 0,
      limit: 10,
      order: 'descend',
      orderBy: '',
      project: '',
      group: '',
      keys: '',
      exportUrl: '',
      platform: 'yii',
      lang: 'zh_CN',
      projects: [],
      groups: [],
      selectedIds: [],
      platforms: [
        {
          label: 'Yii',
          value: 'yii'
        },
        {
          label: 'Javascript',
          value: 'javascript'
        },
        {
          label: 'Android',
          value: 'android'
        },
      ],
      googleFromLang: 'zh_CN',
      googleToLang: 'en_US',
      googleForce: false,
      langs: [
        { label: '中文', value: 'zh_CN' },
        { label: '英语', value: 'en_US'} ,
        { label: '保加利亚', value: 'bg_BG' },
        { label: '波斯尼亚语', value: 'bs_BA' },
        { label: '捷克语', value: 'cs_CZ' },
        { label: '德语', value: 'de_DE' },
        { label: '希腊语', value: 'el_GR' },
        { label: '西班牙语', value: 'es_ES' },
        { label: '法语', value: 'fr_FR' },
        { label: '克罗地亚语', value: 'hr_HR' },
        { label: '匈牙利语', value: 'hu_HU' },
        { label: '意大利语', value: 'it_IT' },
        { label: '立陶宛语', value: 'lt_LT' },
        { label: '波兰语', value: 'pl_PL' },
        { label: '葡萄牙语', value: 'pt_PT' },
        { label: '罗马尼亚语', value: 'ro_RO' },
        { label: '俄语', value: 'ru_RU' },
        { label: '斯洛伐克语', value: 'sk_SK' },
        { label: '塞尔维亚语', value: 'sr_SP' },
        { label: '乌克兰语', value: 'uk_UK' },
        { label: '越南语', value: 'vi_VN' },
      ],
      checkedColumns: [
        'zh_CN',
        'en_US' ,
        'bg_BG',
        'bs_BA',
        'cs_CZ',
        'de_DE',
        'el_GR',
        'es_ES',
        'fr_FR',
        'hr_HR',
        'hu_HU',
        'it_IT',
        'lt_LT',
        'pl_PL',
        'pt_PT',
        'ro_RO',
        'ru_RU',
        'sk_SK',
        'sr_SP',
        'uk_UK',
        'vi_VN',
      ],
      showDeleteDialog: false,
      showGoogleDialog: false,
    }
  },
  setup() {
    // console.log(process.env.VUE_APP_BASE_API)
    window.$message = useMessage()
    // const message = useMessage();
    // let columnsRef = reactive(createColumns())
    let columnsRef = reactive([])
    return {
      list: list,
      columns: columnsRef,
      rowKey: (row) => row.id,
    };
  },
  mounted() {
    this.getList()
    this.refreshExportUrl()
    this.checkedColumns = readColumns('TranslationList', this.checkedColumns)
    this.columns = reactive(createColumns(this.checkedColumns))
  },
  methods: {
    error() {
      window.$message.error("")
    },
    getList() {
      const params = {
        'page': this.page,
        'limit': this.limit,
        'project': this.project,
        'group': this.group,
        'order': this.order,
        'orderBy': this.orderBy,
      }
      apiGetTranslations(params).then(response => {
        this.page = response.data.page
        this.totalPage = response.data.totalPage
        this.limit = response.data.limit
        this.list = []
        for (var i=0; i<response.data.list.length; i++) {
          this.list.push(response.data.list[i])
        }
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
    },
    onLangSelected(lang) {
      this.lang = lang
      this.refreshExportUrl()
    },
    onPlatformSelected() {
      this.refreshExportUrl()
    },
    onGoogleFromLangSelected(lang) {
      this.googleFromLang = lang
    },
    onGoogleToLangSelected(lang) {
      this.googleToLang = lang
    },
    onProjectSelected(project) {
      this.project = project
      // this.getGroups()
      this.getList()
    },
    onGroupSelected(group) {
      this.group = group
      this.getList()
    },
    onPageChanged(page) {
      this.page = page
      this.getList()
    },
    onPageSizeChanged(pageSize) {
      this.page = 1
      this.limit = pageSize
      this.getList()
    },
    refreshExportUrl() {
      this.exportUrl = process.env.VUE_APP_BASE_API+'/api/translation/export?X-Auth-Token='+getToken()+'&platform='+this.platform+'&lang='+this.lang
    },
    onRowChecked(rowKeys) {
      this.selectedIds = rowKeys
    },
    onClickDeleteBtn () {
      this.showDeleteDialog = true
    },
    onClickDeleteDialogYes() {
      const params = {
        ids: this.selectedIds
      }
      apiDeleteTranslations(params).then((response) => {
        if (response.data.count > 0) {
          this.getList()
        }
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
      this.showDeleteDialog = false
    },
    onClickDeleteDialogNo() {
      this.showDeleteDialog = false
    },
    onClickGoogleBtn () {
      this.showGoogleDialog = true
    },
    onClickGoogleDialogYes() {
      const params = {
        ids: this.selectedIds,
        force: this.googleForce,
        from: this.googleFromLang,
        to: this.googleToLang,
      }
      apiPostGoogle(params).then((response) => {
        if (response.data.count > 0) {
          this.getList()
        }
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
      this.showGoogleDialog = false
    },
    onClickGoogleDialogNo() {
      this.showGoogleDialog = false
    },
    onSorterChanged(sorter) {
      // 排序
      for(let i=0; i<this.columns.length; i++) {
        let column = this.columns[i]
        if (column.key == sorter.columnKey) {
          // console.log(column.key)
          column.sortOrder = sorter.order
          this.orderBy = column.key
          this.order = sorter.order
          this.getList()
          break
        }
      }
    },
    onColumnsChanged(columns) {
      // 列选择
      this.checkedColumns = columns
      this.columns = reactive(createColumns(this.checkedColumns))
      saveColumns('TranslationList', this.checkedColumns)

      // console.log(this.checkedColumns)
    },
    // getProjects() {
    //   // 拉取项目列表
    //   apiGetProjects().then(response => {
    //     this.projects = []
    //     for (var i=0; i< response.data.list.length; i++)
    //     {
    //       if (response.data.list[i].project === '') {
    //         continue
    //       }
    //       this.projects.push({
    //         label: response.data.list[i].project,
    //         value: response.data.list[i].project
    //       })
    //       this.getGroups()
    //     }
    //   }).catch(err => {
    //     window.$message.error(err.message)
    //     console.log(err)
    //   })
    // },
    // getGroups() {
    //   // 拉取分组列表
    //   const params = {
    //     project: this.project
    //   }
    //   apiGetGroups(params).then(response => {
    //     this.groups = []
    //     for (var i=0; i< response.data.list.length; i++)
    //     {
    //       if (response.data.list[i].group === '') {
    //         continue
    //       }
    //       this.groups.push({
    //         label: response.data.list[i].group,
    //         value: response.data.list[i].group
    //       })
    //     }
    //   }).catch(err => {
    //     window.$message.error(err.message)
    //     console.log(err)
    //   })
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
