
import { NIcon } from "naive-ui";
import { h } from "vue";
import Cookies from "js-cookie";

export function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

export function saveColumns(key, columns = []) {
  let strColumns = columns.join(',')
  return Cookies.set('Column'+key, strColumns)
}

export function readColumns(key, dftColumns = []) {
  const columns = Cookies.get('Column'+key)
  if (columns === undefined) {
    return dftColumns
  } else {
    return arrayUnique(columns.split(','))
  }
}
export function arrayUnique(itmes) {
  return [...new Set(itmes)]
}