import http from '@/http'

export function getMyProfile() {
  return http({
    url: '/api/my/profile',
    method: 'get'
  })
}

export function putMyProfile(data) {
  return http({
    url: '/api/my/profile',
    method: 'put',
    data
  })
}

