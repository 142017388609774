<template>
  <n-space vertical>
    <n-select v-model:value="project" :options="projects" @update:value="onProjectSelected" clearable/>
  </n-space>
</template>

<script>
// import { defineComponent } from "vue";
import { useMessage } from "naive-ui";
import { apiGetProjects } from "@/apis/translation/project";

export default {
  props: ['propProject'],
  data() {
    return {
      project: '',
      projects: [
      ],
    }
  },
  setup() {
    // console.log(process.env.VUE_APP_BASE_API)
    window.$message = useMessage()
    return {
    };
  },
  mounted() {
    this.project = this.propProject
    this.getProjects()
  },
  methods: {
    onProjectSelected() {
      this.$emit('projectSelected', this.project)
    },
    getProjects() {
      apiGetProjects().then(response => {
        this.projects = []
        for (var i=0; i< response.data.list.length; i++)
        {
          if (response.data.list[i].project === '') {
            continue
          }
          this.projects.push({
            label: response.data.list[i].project,
            value: response.data.list[i].project
          })
        }
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
