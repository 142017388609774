<template>
  <n-space vertical>
    <n-space>
      <n-grid x-gap="12" :cols="4">
        <n-gi>
          <n-input v-model:value="project" type="text" placeholder="project" />
        </n-gi>
        <n-gi>
          <n-space vertical>
            <n-select v-model:value="project" :options="projects" @update:value="onProjectSelected"/>
          </n-space>
        </n-gi>
        <n-gi>
          <n-input v-model:value="group" type="text" placeholder="group" />
        </n-gi>
        <n-gi>
          <n-space vertical>
            <n-select v-model:value="group" :options="groups"/>
          </n-space>
        </n-gi>
      </n-grid>
    </n-space>
      <n-input
        v-model:value="keys"
        type="textarea"
        placeholder="分号;或者换行符分隔"
      />
      <n-button @click="onClickPost">
        导入
      </n-button>
  </n-space>
</template>

<script>
// import { defineComponent } from "vue";
import { useMessage } from "naive-ui";
import { apiPostTranslations } from "@/apis/translation/translations";
import { apiGetProjects } from "@/apis/translation/project";
import { apiGetGroups } from "@/apis/translation/group";
// const data = [
//   { no: 3, title: "Wonderwall", length: "4:18" },
//   { no: 4, title: "Don't Look Back in Anger", length: "4:48" },
//   { no: 12, title: "Champagne Supernova", length: "7:27" }
// ];
export default {
  data() {
    return {
      project: '',
      group: '',
      keys: '',
      projects: [],
      groups: [],
    }
  },
  setup() {
    // console.log(process.env.VUE_APP_BASE_API)
    window.$message = useMessage()
    // const message = useMessage();
    // getMyProfile()
    // alert()
    return {
      info() {
        // message.info("I don't know why nobody told you how to unfold your love", {
        //   keepAliveOnHover: true
        // });
      },
      // error() {
      //   message.error("Once upon a time you dressed so fine");
      // },
      warning() {
        // message.warning("How many roads must a man walk down");
      },
      success() {
        // message.success("'Cause you walked hand in hand With another man in my place");
      },
      loading() {
        // message.loading("If I were you, I will realize that I love you more than any other guy");
      }
    };
  },
  mounted() {
    this.getProjects()
    // this.getList()
    // this.list =  [
    //   { no: 3, title: "Wonderwall", length: "4:18" },
    //   { no: 4, title: "Don't Look Back in Anger", length: "4:48" },
    //   { no: 12, title: "Champagne Supernova", length: "7:27" }
    // ];
  },
  methods: {
    onClickPost() {
      const params = {
        project: this.project,
        group: this.group,
        keys: this.keys,
      }
      apiPostTranslations(params).then(response => {
        window.$message.error('创建记录' + response.data.count)
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
    },
    onProjectSelected(value, option) {
      console.log(option)
      this.getGroups()
    },
    getProjects() {
      apiGetProjects().then(response => {
        this.projects = []
        for (var i=0; i< response.data.list.length; i++)
        {
          if (response.data.list[i].project === '') {
            continue
          }
          this.projects.push({
            label: response.data.list[i].project,
            value: response.data.list[i].project
          })
          this.getGroups()
        }
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
    },
    getGroups() {
      const params = {
        project: this.project
      }
      apiGetGroups(params).then(response => {
        this.groups = []
        for (var i=0; i< response.data.list.length; i++)
        {
          if (response.data.list[i].group === '') {
            continue
          }
          this.groups.push({
            label: response.data.list[i].group,
            value: response.data.list[i].group
          })
        }
      }).catch(err => {
        window.$message.error(err.message)
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
