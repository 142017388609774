<template>
  <n-space vertical>
    <n-input v-model:value="username" type="text" placeholder="用户名" />
     <n-input
      type="password"
      v-model:value="password"
      show-password-on="mousedown"
      placeholder="密码"
      :maxlength="10"
    />
    <n-button @click="onClickLogin">Login</n-button>
  </n-space>
</template>

<script>
// import { defineComponent } from "vue";
import { useMessage } from "naive-ui";
import { getMyProfile } from "@/apis/user/profile";
import { apiLogin } from "@/apis/user/login";
import Cookies from "js-cookie";

export default {
  data() {
      return {
          username: '2046',
          password: '2046'
      }
  },
  setup() {
    // console.log(process.env.VUE_APP_BASE_API)
    window.$message = useMessage()
    // const message = useMessage();
    getMyProfile()
    return {
    };
  },
  mounted() {
  },
  methods: {
    error() {
      window.$message.error("")
    },
    onClickLogin() {
        const params = {
            username: this.username,
            password: this.password
        }
        console.log(params)
        apiLogin(params).then(response => {
          console.log(response)
          if (response.data.role !== 'Guest') {
            console.log(response.data.accessToken)
            Cookies.set('accessToken', response.data.accessToken)
            this.$router.push(
              {
                name: 'TranslationList',
                params: {
                }
              }
            )
          }
        }).catch(err => {
          window.$message.error(err.message)
          console.log(err)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
