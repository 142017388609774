import http from '@/http'

export function apiGetTranslation(params) {
  return http({
    url: '/api/translation',
    method: 'get',
    params: params
  })
}

export function apiPutTranslation(data) {
  return http({
    url: '/api/translation',
    method: 'put',
    data
  })
}

export function apiPostGoogle(data) {
  return http({
    url: '/api/translation/google',
    method: 'post',
    data
  })
}

