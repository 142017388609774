<template>
    <!-- <n-space vertical item-style="display: flex;">
      <span>xxx</span>
       <ul id="array-rendering">
        <li v-for="(item, index) in langs" :key="index">
          {{ item.label }}
        </li>
      </ul>
  
    </n-space> -->
    <n-space vertical item-style="display: flex;">
      <n-button @click="showDialog = true">
        <n-icon>
          <grid-icon />
        </n-icon>
      </n-button>
      <n-modal v-model:show="showDialog" transform-origin="mouse" @after-leave="onCloseDialog">
        <n-card
          style="width: 200px;"
          title="选择"
          :bordered="false"
          size="huge"
          role="dialog"
          aria-modal="true"
        >
          <n-checkbox v-model:checked="checkAll" :indeterminate="partialChecked" @update:checked="onAllChecked" label="全选"/>
          <n-divider />
          <n-checkbox-group v-model:value="checkedColumns" @update:value="onUpdateChecks">
            <n-space vertical item-style="display: flex;">
              <n-checkbox v-for="(item, index) in propItems" :value="item.value" :label="item.label" :key="index"/>
            </n-space>
          </n-checkbox-group>
        </n-card>
      </n-modal>
    </n-space>
</template>

<script>
// import { defineComponent } from "vue";
import { useMessage } from "naive-ui";
import { GridOutline as GridIcon } from "@vicons/ionicons5";

export default {
  props: ['propItems', 'propCheckedColumns'],
  components: {
    GridIcon
  },
  data() {
    return {
      changed: false,
      checkAll: false,
      showDialog: false,
      checkedColumns: [],
    }
  },
  setup() {
    // console.log(process.env.VUE_APP_BASE_API)
    window.$message = useMessage()
    // const message = useMessage();
    return {
    };
  },
  computed: {
    partialChecked() {
      // 是否部分选择
      return this.checkedColumns.length > 0 && this.checkedColumns.length < this.propItems.length
    }
  },
  mounted() {
    this.checkedColumns = this.propCheckedColumns
    this.checkAll = this.checkedColumns.length === this.propItems.length
  },
  methods: {
    error() {
      window.$message.error("")
    },
    onUpdateChecks() {
      // 选择变更
      this.changed = true
      // console.log(this.checkedColumns)
    },
    onCloseDialog() {
      if (this.changed) {
        this.$emit('columnsChanged', this.checkedColumns)
      }
    },
    onAllChecked(checked) {
      // 全选
      if (this.partialChecked) {
        this.checkedColumns = []
        this.checkAll = false
        this.changed = true
      } else if (checked) {
        const columns = []
        for (let key in this.propItems) {
          columns.push(this.propItems[key].value)
        }
        this.checkedColumns = columns
        this.checkAll = true
        this.changed = true
      } else {
        this.checkedColumns = []
        this.checkAll = false
        this.changed = true
      }
    },
  },
  watch:{ 
    propCheckedColumns: {
      handler(newVal/*, oldVal*/) {
        this.checkedColumns = newVal
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
