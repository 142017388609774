<template>
  <n-message-provider>
    <n-space vertical size="large">
    <n-layout>
      <n-layout-header>
        <NavBar />
      </n-layout-header>
      <n-layout-content content-style="padding: 24px;">
        <!-- 路由出口 -->
        <!-- 路由匹配到的组件将渲染在这里 -->
        <router-view></router-view>
      </n-layout-content>
      <n-layout-footer>footer</n-layout-footer>
    </n-layout>
    </n-space>
  </n-message-provider>
</template>

<script>
import NavBar from './components/NavBar.vue'
// import { useMessage } from "naive-ui";

export default {
  name: 'App',
  components: {
    NavBar
  },
  // data() {
  //   return {
  //     message: 'You loaded this page on ' + new Date().toLocaleString()
  //   }
  // },
  setup() {
    // window.$message = useMessage()
  },
  mounted() {
    // console.log(this.$route.name)
    // setInterval(() => {
    //   this.counter++
    // }, 1000)
  },
  methods: {
    // reverseMessage() {
    //   this.message = this.message
    //     .split('')
    //     .reverse()
    //     .join('')
    // }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
