<template>
  <n-space vertical>
    <n-select v-model:value="lang" :options="langs" @update:value="onLangSelected"/>
  </n-space>
</template>

<script>
// import { defineComponent } from "vue";
import { useMessage } from "naive-ui";

export default {
  props: ['propLang'],
  data() {
    return {
      lang: 'zh_CN',
      langs: [
        { label: '中文', value: 'zh_CN' },
        { label: '英语', value: 'en_US'} ,
        { label: '保加利亚', value: 'bg_BG' },
        { label: '波斯尼亚语', value: 'bs_BA' },
        { label: '捷克语', value: 'cs_CZ' },
        { label: '德语', value: 'de_DE' },
        { label: '希腊语', value: 'el_GR' },
        { label: '西班牙语', value: 'es_ES' },
        { label: '法语', value: 'fr_FR' },
        { label: '克罗地亚语', value: 'hr_HR' },
        { label: '匈牙利语', value: 'hu_HU' },
        { label: '意大利语', value: 'it_IT' },
        { label: '立陶宛语', value: 'lt_LT' },
        { label: '波兰语', value: 'pl_PL' },
        { label: '葡萄牙语', value: 'pt_PT' },
        { label: '罗马尼亚语', value: 'ro_RO' },
        { label: '俄语', value: 'ru_RU' },
        { label: '斯洛伐克语', value: 'sk_SK' },
        { label: '塞尔维亚语', value: 'sr_SP' },
        { label: '乌克兰语', value: 'uk_UK' },
        { label: '越南语', value: 'vi_VN' },
      ],
    }
  },
  setup() {
    // console.log(process.env.VUE_APP_BASE_API)
    window.$message = useMessage()
    // const message = useMessage();
    return {
      // username:'root',
      // password:'password',
      info() {
        // message.info("I don't know why nobody told you how to unfold your love", {
        //   keepAliveOnHover: true
        // });
      },
      // error() {
      //   message.error("Once upon a time you dressed so fine");
      // },
      warning() {
        // message.warning("How many roads must a man walk down");
      },
      success() {
        // message.success("'Cause you walked hand in hand With another man in my place");
      },
      loading() {
        // message.loading("If I were you, I will realize that I love you more than any other guy");
      }
    };
  },
  mounted() {
    this.lang = this.propLang
  },
  methods: {
    error() {
      window.$message.error("")
    },
    onLangSelected() {
      this.$emit('langSelected', this.lang)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
