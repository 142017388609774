import http from '@/http'

export function apiGetGroups(params) {
  return http({
    url: '/api/translation/groups',
    method: 'get',
    params: params
  })
}


